<template>
    <div class="body">
        <Header />
        <div role="main" class="terms">
            <section class="page-header mb-0">
                <div class="container">
                    <div class="row align-items-center mt-5 pt-5">
                        <div class="col-md-8 text-left">
                            <span class="tob-sub-title text-color-primary d-block">Legal terms</span>
                            <h1 class="font-weight-bold">법적고지</h1>
                            <!-- <p class="lead">sub title</p> -->
                        </div>
                        <div class="col-md-4">
                            <ul class="breadcrumb justify-content-start justify-content-md-end mb-0">
                                <li><router-link to="/">Home</router-link></li>
                                <li class="active">Terms</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <div class="container">
                <ul class="nav nav-tabs nav-tabs-default" id="tabDefault" role="tablist">
                    <li class="nav-item" v-for="(item,index) in ['개인정보처리방침']" :key="index">
                        <router-link :to="{
                          name:'Terms', query:{tab_idx: index}
                        }" class="nav-link" :class="{'active': this.$route.query.tab_idx == index}" 
                        aria-expanded="true">{{item}}</router-link>
                    </li>
                </ul>
            </div>

            <div class="container">
                <div class="row">
                    <aside class="sidebar col-md-4 col-lg-3 order-2 order-md-1">
                        <div class="accordion accordion-default accordion-toggle accordion-style-1 accordion-style-1-no-borders-divider" role="tablist">
                            <div class="card">
                                <div class="card-header accordion-header" role="tab" id="categories">
                                    <h3 class="text-3 mb-0">
                                        <p class="pt-3 pb-2 mb-0 font-weight-semibold">현행시행일자</p>
                                    </h3>
                                </div>
                                <div id="toggleCategories" class="accordion-body collapse show" aria-labelledby="categories">
                                    <div class="card-body">
                                        <ul class="list list-unstyled ml-1">
                                            <li class="pb-2" v-for="(item,index) in categorys[this.$route.query.tab_idx]" :key="index">
                                                <a href="#">{{item}} <i class="fas fa-angle-right ml-1 mr-1 pr-2"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </aside>
                    <div class="col-md-8 col-lg-9 order-1 order-md-2 mb-5 mb-md-0 py-3">
                        <pre style="font-family: sans-serif !important;">
                            {{terms[this.$route.query.tab_idx]}}
                        </pre>
                    </div>
                </div>

            </div>

        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/SubHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
    components:{
        Header, Footer
    },
    data(){
        return{
            categorys: [
                [
                    '2023. 01. 01'
                ],
                [
                    '2023. 01. 01'
                ]
            ],
            terms: [
                `개인정보 처리방침

주식회사 원성글로벌(이하 회사라 한다)는 「개인정보 보호법」 제30조에 따라 정부주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.

제1조 (개인정보의 처리 목적)
회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

1. 	홈페이지 회원가입 및 관리
회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만14세 미만 아동의 개인정보 처리 시 법정대리인의 동의 여부 확인, 각종 고지·통지, 고충처리 목적으로 개인정보를 처리합니다.

2. 	민원사무 처리
민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 목적으로 개인정보를 처리합니다.

3. 재화 또는 서비스 제공
서비스 제공, 콘텐츠 제공, 본인인증, 연령인증 등을 목적으로 개인정보를 처리합니다.

4. 마케팅 및 광고에의 활용
신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.

제2조(개인정보의 처리 및 보유 기간)
1. 앱 회원가입 및 관리
앱 회원가입 및 관리와 관련한 개인정보는 수집·이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유·이용됩니다.
보유근거 : 관련 법령에 근거
관련법령: 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년
          소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
          대금결제 및 재화 등의 공급에 관한 기록 : 5년
          계약 또는 청약철회 등에 관한 기록 : 5년
          표시/광고에 관한 기록 : 6개월
예외사유 : 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우, 서비스의 전부 또는 일부를 제한하거나 중지할 수 있습니다. 다만, 이 경우 그 사유 및 기간 등을 회원에게 사전 또는 사후에 공지합니다.

2. 민원사무 처리
민원사무 처리와 관련한 개인정보는 수집·이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유·이용됩니다.
보유근거 : 관련 법령에 근거 (전항과 동일)

3. 마케팅 및 광고에의 활용
마케팅 및 광고에의 활용과 관련한 개인정보는 수집·이용에 관한 동의일로부터 1년까지 위 이용목적을 위하여 보유·이용됩니다.
보유근거 : 관련 법령에 근거 (전항과 동일)

제3조 (개인정보의 제3자 제공)
1. 	회사는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하여, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」, 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
2. 	회사는 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.

제공받는 자의 개인정보 이용목적 : 이메일, 휴대폰번호, 로그인 아이디, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠키 제공받는 자의 보유·이용기간 : 1년

제4조 (개인정보처리 위탁)
1. 	회사는 위탁계약 체결 시 「개인정보 보호법」제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적, 관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
2. 	위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.

제5조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법)
1. 	정보주체는 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
2. 	제1항에 따른권리 행사는 주식회사 원성글로벌에 대해 「개인정보 보호법」시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 주식회사 원성글로벌는 이에 대해 지체 없이 조치하겠습니다.
3. 	제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 처리 방법에 관한 고시(제2020-7호) 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
4. 	개인정보 열람 및 처리정지 요구는 「개인정보 보호법」, 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.
5. 	개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
6. 	회사는 정보주체 관리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.

제6조 (처리하는 개인정보의 항목 작성)
1. 	회사는 다음의 개인정보 항목을 처리하고 있습니다.
A. 	홈페이지 회원가입 및 관리필수항목 : 이메일, 휴대전화번호, 비밀번호, 로그인 아이디, 성별, 생년월일, 이름, 서비스 이용 기록, 접속 로그, 쿠기

제7조 (개인정보의 파기)
1. 	회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
2. 	정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속보전하여야 하는 경우에는 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
3. 	개인정보 파기의 절차 및 방법은 다음과 같습니다.
A. 	파기절차 : 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.
B. 	파기방법 : 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.

제8조 (개인정보의 안전성 확보 조치)
회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
1. 	개인정보 취급 직원의 최소화 및 교육개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.
2. 	해킹 등에 대비한 기술적 대책회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
3. 	개인정보의 암호화이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
4. 	개인정보에 대한 접근 제한개인정보를 처리하는데이터베이스 시스템에 대한 접근권한의부여, 변경, 말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
5. 	문서보안을 위한 잠금 장치 사용개인정보가 포함된 서류, 보조저장매체 등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다
6. 	비인가 자에 대한 출입 통제개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대한 출입통제 절차를 수립, 운영하고 있습니다.

제9조 (개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)
1. 	회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 쿠키를 사용합니다.
2. 	쿠기는 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC컴퓨터내의 하드디스크에 저장되기도 합니다.쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안 접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.쿠키의 설치·운영 및 거부 : 웹 브라우저 상단의 도구>인터넷옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.

제10조 (개인정보 보호책임자)
1. 	회사는 개인정보 처리에 관하 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.개인정보 보호책임자성명 : 박상현직급/직책 : 이사연락처 : 1533-4178이메일 : contact@wonsungglobal.com
2. 	정보주체께서는 회사의 서비스 또는 사업을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.

제11조 (개인정보 열람청구)
정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.

제12조 (권익침해 구제방법)
1. 	정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의신고, 상담에 대하여는 아래의기관에 문의하시기 바랍니다.개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)개인정보침해신고센터 : (국번없이) 118 (priacy.kisa.or.kr)대검찰청 : (국번없이) 1301 (www.spo.go.kr)검찰청 : (국번없이) 182 (cyberbureau.police.go.kr)
2. 	「개인정보 보호법」제35조, 제36조, 제37조의규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는바에 따라 행정심판을 청구할 수 있습니다.행정심판에 대한 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.

이 개인정보처리방침은 2023년 01월 01일부터 적용됩니다.`
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
    pre{
        font-size: 13px;
        font-family: inherit;
        white-space: pre-line;
    }
    .terms .nav{ 
        flex-wrap: nowrap !important;
        overflow-x: auto;
        overflow-y: hidden;
        li{
            white-space: nowrap;
        }
    }
</style>